import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
var __jsx = React.createElement;
function MarkerGeneralPractice(props) {
  return __jsx("svg", _extends({
    xmlns: "http://www.w3.org/2000/svg",
    width: "29",
    height: "43",
    viewBox: "0 0 29 43",
    fill: "none"
  }, props), __jsx("path", {
    fill: "#2A9E41",
    stroke: "#fff",
    strokeWidth: "2",
    d: "M14.5 1c4.813 0 8.235 1.99 10.425 4.915C27.087 8.802 28 12.53 28 16c0 1.468-.522 3.383-1.28 5.427-.768 2.074-1.823 4.392-2.974 6.709-2.304 4.635-5.032 9.33-6.735 12.172a2.915 2.915 0 0 1-5.022 0c-1.703-2.842-4.43-7.537-6.735-12.172-1.151-2.317-2.206-4.635-2.975-6.71C1.522 19.384 1 17.469 1 16c0-3.47.913-7.198 3.074-10.085C6.264 2.99 9.687 1 14.5 1Z"
  }), __jsx("g", {
    fill: "#fff",
    clipPath: "url(#a)"
  }, __jsx("path", {
    d: "M17.244 9.067c0-1.462-1.209-2.647-2.7-2.647-1.493 0-2.702 1.185-2.702 2.647 0 1.462 1.21 2.647 2.701 2.647 1.49.003 2.7-1.18 2.701-2.64v-.007ZM16.114 14.648a.23.23 0 0 0 .231-.227.23.23 0 0 0-.231-.226.23.23 0 0 0-.232.226v.004c0 .123.102.223.228.223h.004ZM15.173 15.005c.19-.04.33-.245.118-.58a1.014 1.014 0 0 1 0-.625c.026-.115-.076-.238-.353-.238-.501 0-.968.283-.918.521.114.565.72 1.022 1.153.922Z"
  }), __jsx("path", {
    d: "m20.101 18.184-.963-3.796c-.433-1.435-1.54-1.993-3.263-1.993h-2.182c-1.722 0-2.83.558-3.263 1.993l-.96 3.796c-.166.785.156 1.365.945 1.487l1.347.227v1.25h6.07v-1.235l1.34-.227c.774-.137 1.096-.717.93-1.502Zm-4.344 1.324a.602.602 0 0 1-.758-.371v-.001l-.137-.39v-.03a1.129 1.129 0 0 0-.93-.867l-2.792-.524c.07-.596.353-1.148.8-1.558.424-.42.925-.758 1.476-.996.038.836.558 1.41 1.491 1.613.133.025.268.042.402.053l.82 2.327a.581.581 0 0 1-.368.743h-.004Zm.645-3.417c-.47 0-.938-.03-1.404-.09-1.138-.252-1.18-1.055-1.164-1.397a1.353 1.353 0 0 1-.209-.525c-.072-.498.634-.9 1.26-.9.13-.003.26.02.379.071a1.38 1.38 0 0 1 1.389 1.164c.068.546.698.502.683.885a.827.827 0 0 1-.934.792Z"
  })), __jsx("defs", null, __jsx("clipPath", {
    id: "a"
  }, __jsx("path", {
    fill: "#fff",
    d: "M9 6h12v16H9z"
  }))));
}
export default MarkerGeneralPractice;
import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from 'react';
function SpecialtyHappy(props) {
  return __jsx("svg", _extends({}, props, {
    width: "17",
    height: "16",
    viewBox: "0 0 17 16",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }), __jsx("path", {
    d: "M3.12161 14.1482L6.25816 12.2518V16H10.975V12.2617L14.1067 14.158L16.501 9.95557L13.2831 8.00492L16.5298 6.05927L14.1355 1.85185L10.975 3.74815V0H6.25337V3.73827L3.12161 1.84198L0.727295 6.04442L3.94525 8.01977L0.727295 9.94072L3.12161 14.1482ZM7.43135 12.1728C7.43135 12.3092 7.32415 12.4197 7.19192 12.4197C7.05975 12.4197 6.9525 12.3092 6.9525 12.1728C6.95192 11.7243 7.27718 11.3465 7.70912 11.2938L7.73787 11.7877C7.56112 11.8268 7.43404 11.9865 7.43135 12.1728ZM10.6589 6.62717C10.6362 6.42669 10.7754 6.24525 10.9698 6.2218C11.1642 6.19834 11.3402 6.34189 11.3628 6.54231C11.366 6.57054 11.366 6.59894 11.3628 6.62717C11.3628 7.65435 10.4051 8.10863 9.68684 8.20739L9.72512 7.46171C10.0986 7.3679 10.635 7.14568 10.635 6.62717H10.6589ZM7.51758 7.52595L7.55587 8.25183C7.26495 8.23415 7.01518 8.46297 6.99798 8.76297C6.98084 9.06298 7.20272 9.32056 7.49364 9.33829H8.08261L7.9581 7.01232C7.35358 6.63595 7.15941 5.82548 7.52444 5.20213C7.88935 4.57873 8.6753 4.37848 9.27981 4.75485C9.88432 5.13121 10.0785 5.94165 9.71347 6.56511C9.60627 6.74826 9.45741 6.90177 9.27981 7.01232L9.05952 11.0914H9.67244C9.88747 11.0914 10.0625 10.9131 10.0651 10.6914C10.0651 10.4677 9.88935 10.2864 9.67244 10.2864V9.79257C10.1472 9.75604 10.5607 10.1233 10.5962 10.6128C10.6316 11.1024 10.2756 11.5289 9.80084 11.5654C9.7581 11.5687 9.71524 11.5687 9.67244 11.5654H9.01164L8.94455 12.8395H8.25021L8.10175 10.0593H7.4697C6.78998 10.0524 6.24437 9.47872 6.25098 8.77776C6.25759 8.07687 6.81392 7.51416 7.49364 7.521L7.51758 7.52595Z",
    fill: "white"
  }));
}
export default SpecialtyHappy;
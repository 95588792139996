var __jsx = React.createElement;
import React, { useCallback } from 'react';
import { AdvancedMarker } from '@vis.gl/react-google-maps';
import MarkerGeneralPractice from '../../../static/images/icons/MarkerGeneralPractice';
import MarkerEthos from '../../../static/images/icons/MarkerEthos';
import MarkerPetResorts from '../../../static/images/icons/MarkerPetResorts';
import MarkerEquine from '../../../static/images/icons/MarkerEquine';
import { BUSINESS_TYPE_FOLDER } from '../../organisms/GoogleMapsHappyHealthyPetsV2/constants';
function getIcon(location) {
  if (location.fId === BUSINESS_TYPE_FOLDER.GENERAL_PRACTICE.folderId) {
    return __jsx(MarkerGeneralPractice, null);
  }
  if (location.fId === BUSINESS_TYPE_FOLDER.ETHOS.folderId) {
    return __jsx(MarkerEthos, null);
  }
  if (location.fId === BUSINESS_TYPE_FOLDER.PET_RESORTS.folderId) {
    return __jsx(MarkerPetResorts, null);
  }
  if (location.fId === BUSINESS_TYPE_FOLDER.EQUINE.folderId) {
    return __jsx(MarkerEquine, null);
  }
}

/**
 * Wrapper Component for an AdvancedMarker for a single location.
 */
export var LocationMarker = function LocationMarker(_ref) {
  var hasOldIcons = _ref.hasOldIcons,
    location = _ref.location,
    onClick = _ref.onClick,
    setMarkerRef = _ref.setMarkerRef;
  var handleClick = useCallback(function () {
    return onClick(location);
  }, [onClick, location]);
  var ref = useCallback(function (marker) {
    return setMarkerRef(marker, location.yId);
  }, [setMarkerRef, location.yId]);
  return __jsx(AdvancedMarker, {
    className: "nva-map__marker",
    position: {
      lat: location.lat,
      lng: location.lng
    },
    ref: ref,
    onClick: handleClick
  }, hasOldIcons && getIcon(location));
};
import React from "react";
var __jsx = React.createElement;
import ListHappyIcon from '../../../static/images/icons/ListHappyIcon';
import ViewOnMap from '../../../static/images/icons/ViewOnMap';
function MapListToggle(_ref) {
  var toggleMap = _ref.toggleMap,
    setToggleMap = _ref.setToggleMap;
  return __jsx("div", {
    className: "nva-maps-hhpets__map-list-toggle map-list-toggle"
  }, __jsx("button", {
    className: "map-toggle".concat(!toggleMap ? ' enabled' : ''),
    disabled: toggleMap === false,
    onClick: function onClick() {
      setToggleMap(false);
    }
  }, __jsx("span", {
    className: "icon"
  }, __jsx(ViewOnMap, {
    className: "view-on-map-icon"
  })), __jsx("span", null, "MAP")), __jsx("button", {
    className: "nva-maps-hhpets__list-toggle list-toggle".concat(!toggleMap ? '' : ' enabled is-enabled'),
    disabled: toggleMap === true,
    onClick: function onClick() {
      setToggleMap(true);
    }
  }, __jsx("span", {
    className: "icon"
  }, __jsx(ListHappyIcon, {
    className: "nva-maps-hhpets__list-icon-buttons list-icon-buttons"
  })), __jsx("span", null, "LIST(A-Z)")));
}
export default MapListToggle;
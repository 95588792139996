import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from 'react';
function PetResortHappy(props) {
  return __jsx("svg", _extends({}, props, {
    width: "20",
    height: "18",
    viewBox: "0 0 20 18",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }), __jsx("path", {
    d: "M19.078 8.24761L17.7723 4.58808C17.7394 4.49706 17.6545 4.4372 17.5603 4.43848H15.0886C14.8542 2.44185 12.7117 0.928558 9.99994 0.928558C7.28828 0.928558 5.12895 2.44185 4.91135 4.43848H2.43959C2.34537 4.4372 2.26053 4.49706 2.22756 4.58808L0.905194 8.24761C0.865105 8.37112 0.929735 8.50489 1.04955 8.5462C1.07138 8.55374 1.0942 8.55781 1.11722 8.55834H1.92068V16.6139H1.46315V17.5H18.5535V16.6311H18.0737V8.5756H18.8715C18.9948 8.57814 19.0967 8.47713 19.0991 8.35002C19.0998 8.31472 19.0925 8.2796 19.078 8.24761ZM9.99994 1.79741C12.3601 1.79741 14.2962 3.13233 14.2962 4.77221C14.2962 6.41209 12.388 7.75278 10.0278 7.75278C7.66765 7.75278 5.72597 6.41784 5.72597 4.77221C5.72597 3.12658 7.63977 1.79741 9.99994 1.79741ZM7.51142 16.1823H4.10789V11.7518H7.51142V16.1823ZM11.7073 16.1823H8.29257V11.7518H11.7073V16.1823ZM15.892 16.1823H12.4885V11.7518H15.892V16.1823ZM16.45 11.0441H3.54993V10.0083L4.62679 8.59858H15.4122L16.4667 10.0198L16.45 11.0441Z",
    fill: "white"
  }), __jsx("path", {
    d: "M9.13501 14.623C9.32295 14.623 9.47535 14.4659 9.47535 14.272C9.47843 14.0782 9.32855 13.9185 9.14061 13.9153C9.13872 13.9152 9.13689 13.9152 9.13501 13.9152C8.94392 13.9152 8.78906 14.075 8.78906 14.272C8.78906 14.4659 8.94135 14.623 9.12935 14.6231C9.13123 14.6231 9.13306 14.6231 9.13501 14.623Z",
    fill: "white"
  }), __jsx("path", {
    d: "M9.33046 4.30613C9.72132 4.15301 9.91841 3.70275 9.77126 3.29918C9.67943 2.87781 9.27783 2.60939 8.86738 2.69501C8.47801 2.85302 8.28229 3.30271 8.42658 3.70771C8.51829 4.12866 8.92115 4.39536 9.33046 4.30613Z",
    fill: "white"
  }), __jsx("path", {
    d: "M10.6639 4.30613C11.0663 4.39682 11.466 4.14236 11.5678 3.73073C11.712 3.32573 11.5163 2.87604 11.127 2.71804C10.7228 2.62775 10.3223 2.88523 10.2231 3.29919C10.0759 3.70275 10.273 4.15302 10.6639 4.30613Z",
    fill: "white"
  }), __jsx("path", {
    d: "M11.8078 5.4109C12.1701 5.63012 12.636 5.50678 12.8512 5.13471C13.1114 4.79643 13.0619 4.30564 12.7396 4.02995C12.3754 3.81506 11.9114 3.94044 11.6962 4.31189C11.4334 4.64735 11.4833 5.13887 11.8078 5.4109Z",
    fill: "white"
  }), __jsx("path", {
    d: "M8.19216 5.41087C8.51205 5.13554 8.55919 4.64677 8.29816 4.31185C8.08513 3.94114 7.62285 3.81556 7.26033 4.02991C6.93805 4.3056 6.8885 4.7964 7.14879 5.13467C7.36393 5.50674 7.82988 5.63009 8.19216 5.41087Z",
    fill: "white"
  }), __jsx("path", {
    d: "M8.354 6.23947C8.354 7.02778 9.09046 6.86089 10.0278 6.86089C10.9653 6.86089 11.6738 7.02778 11.6738 6.23947C11.619 5.32111 10.8919 4.59873 9.99995 4.57657C9.1188 4.61327 8.40766 5.33176 8.354 6.23947Z",
    fill: "white"
  }));
}
export default PetResortHappy;
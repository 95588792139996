import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
var __jsx = React.createElement;
function MarkerPetResorts(props) {
  return __jsx("svg", _extends({
    xmlns: "http://www.w3.org/2000/svg",
    width: "29",
    height: "43",
    viewBox: "0 0 29 43",
    fill: "none"
  }, props), __jsx("path", {
    fill: "#2A9E41",
    stroke: "#fff",
    strokeWidth: "2",
    d: "M14.5 1c4.813 0 8.235 1.99 10.425 4.915C27.087 8.802 28 12.53 28 16c0 1.468-.522 3.383-1.28 5.427-.768 2.074-1.823 4.392-2.974 6.709-2.304 4.635-5.032 9.33-6.735 12.172a2.915 2.915 0 0 1-5.022 0c-1.703-2.842-4.43-7.537-6.735-12.172-1.151-2.317-2.206-4.635-2.975-6.71C1.522 19.384 1 17.469 1 16c0-3.47.913-7.198 3.074-10.085C6.264 2.99 9.687 1 14.5 1Z"
  }), __jsx("g", {
    fill: "#fff",
    clipPath: "url(#a)"
  }, __jsx("path", {
    d: "m21.639 13.06-.98-2.744a.168.168 0 0 0-.159-.112h-1.854c-.175-1.498-1.782-2.632-3.816-2.632s-3.653 1.135-3.816 2.632H9.16a.167.167 0 0 0-.16.112l-.991 2.745a.178.178 0 0 0 .108.224.169.169 0 0 0 .05.009h.604v6.041h-.344V20h12.818v-.651h-.36v-6.042h.599a.17.17 0 0 0 .17-.17.18.18 0 0 0-.015-.076ZM14.83 8.224c1.77 0 3.222 1.001 3.222 2.231s-1.431 2.236-3.201 2.236-3.227-1.002-3.227-2.236c0-1.234 1.436-2.23 3.206-2.23Zm-1.866 10.789H10.41v-3.323h2.553v3.323Zm3.146 0h-2.56v-3.323h2.56v3.323Zm3.139 0h-2.553v-3.323h2.553v3.323Zm.419-3.854H9.992v-.777l.808-1.057h8.09l.79 1.066-.012.768Z"
  }), __jsx("path", {
    d: "M14.181 17.842a.26.26 0 0 0 .256-.263.26.26 0 0 0-.252-.267h-.004c-.143 0-.26.12-.26.267a.26.26 0 0 0 .256.263h.004ZM14.328 10.105a.592.592 0 0 0 .33-.755.578.578 0 0 0-.678-.454.601.601 0 0 0-.33.76.574.574 0 0 0 .678.449ZM15.328 10.105a.573.573 0 0 0 .678-.432.601.601 0 0 0-.33-.76.573.573 0 0 0-.678.437c-.11.302.037.64.33.755ZM16.186 10.933a.564.564 0 0 0 .782-.207.608.608 0 0 0-.083-.828.568.568 0 0 0-.783.211.6.6 0 0 0 .084.824ZM13.474 10.933a.604.604 0 0 0 .08-.824.564.564 0 0 0-.779-.211.609.609 0 0 0-.084.828c.162.28.511.372.783.207ZM13.596 11.555c0 .591.552.466 1.255.466.703 0 1.235.125 1.235-.466-.041-.689-.587-1.23-1.256-1.247-.66.027-1.194.566-1.234 1.247Z"
  })), __jsx("defs", null, __jsx("clipPath", {
    id: "a"
  }, __jsx("path", {
    fill: "#fff",
    d: "M8 7h14v13H8z"
  }))));
}
export default MarkerPetResorts;
import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from 'react';
function FilterBar(props) {
  return __jsx("svg", _extends({
    viewBox: "0 0 20 15",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), __jsx("path", {
    d: "M4 7.5H16M1 1.5H19M7 13.5H13"
  }));
}
export default FilterBar;
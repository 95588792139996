import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from 'react';
function ListHappyIcon(props) {
  return __jsx("svg", _extends({
    viewBox: "0 0 14 10",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), __jsx("path", {
    d: "M4.58333 1H13.25M4.58333 5H13.25M4.58333 9H13.25M1.25 1H1.25667M1.25 5H1.25667M1.25 9H1.25667"
  }));
}
export default ListHappyIcon;
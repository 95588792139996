var __jsx = React.createElement;
import * as React from 'react';
function EquineHappy(props) {
  return __jsx("svg", {
    width: "20",
    height: "19",
    viewBox: "0 0 20 19",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __jsx("path", {
    d: "M13.3918 2.96638C13.2291 1.94513 12.9382 0.948219 12.5261 0C12.2518 0.26125 12.0208 0.564063 11.8403 0.897157C11.9775 1.34603 12.1408 1.86853 12.2833 2.38035C12.6371 2.55431 13.0118 2.74966 13.3918 2.96638Z",
    fill: "white"
  }), __jsx("path", {
    d: "M13.1014 10.7564C14.8957 11.2836 16.2417 11.3947 17.3236 12.0911C17.3966 12.1362 17.4809 12.16 17.5664 12.16C18.3478 12.16 19.4456 10.2707 19.1499 9.10931C18.881 8.10647 15.0756 4.35931 11.6289 2.92362C11.281 1.62034 10.7371 0 10.7371 0C10.7371 0 7.64424 2.11138 6.24596 4.85035C5.71812 5.84785 5.57561 8.09103 6.15571 9.91146C5.44261 8.94781 4.90646 7.86482 4.57277 6.71354C4.26461 8.64619 4.25215 10.6145 4.53596 12.5507C4.53596 12.5507 2.95243 10.4922 2.74105 8.36C1.56958 9.60569 0.228898 11.0835 0.228898 11.0835C0.228898 11.0835 0.113115 13.11 1.84924 15.4636C3.12996 17.1196 4.87855 18.3516 6.86821 19C8.83649 16.6749 10.2769 13.9502 11.091 11.0147C10.8267 10.5557 10.2199 9.5 10.2567 8.61353C10.6528 9.70603 11.6342 10.3075 13.1014 10.7564ZM11.8082 4.49112C12.1205 4.48875 12.3752 4.73872 12.3782 5.05103C12.3812 5.36275 12.1306 5.61806 11.8189 5.62103C11.5072 5.6234 11.2519 5.37344 11.2489 5.06113C11.2489 5.05756 11.2489 5.0546 11.2489 5.05103C11.2519 4.74288 11.5006 4.49409 11.8082 4.49112Z",
    fill: "white"
  }));
}
export default EquineHappy;
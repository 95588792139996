import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from 'react';
function GeneralPracticeHappy(props) {
  return __jsx("svg", _extends({}, props, {
    width: "15",
    height: "21",
    viewBox: "0 0 15 21",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }), __jsx("path", {
    d: "M10.7197 4.42306C10.7197 2.47363 9.10714 0.893311 7.11792 0.893311C5.12868 0.893311 3.51611 2.47363 3.51611 4.42306C3.51611 6.3725 5.12868 7.95278 7.11792 7.95278C9.10428 7.95552 10.7169 6.37968 10.7197 4.43298C10.7197 4.42967 10.7197 4.42636 10.7197 4.42306Z",
    fill: "white"
  }), __jsx("path", {
    d: "M9.21219 11.8642C9.38263 11.8642 9.52077 11.7288 9.52071 11.5617C9.52071 11.3947 9.38252 11.2594 9.21208 11.2594C9.04164 11.2594 8.90356 11.3948 8.90356 11.5619C8.90356 11.5635 8.90356 11.5651 8.90362 11.5668C8.90362 11.7311 9.03942 11.8642 9.20706 11.8643C9.20881 11.8643 9.21045 11.8643 9.21219 11.8642Z",
    fill: "white"
  }), __jsx("path", {
    d: "M7.95767 12.3402C8.21062 12.2856 8.39779 12.013 8.11452 11.5668C8.02683 11.2958 8.02683 11.0049 8.11452 10.7339C8.14992 10.5802 8.0133 10.4166 7.64403 10.4166C6.97627 10.4166 6.35405 10.7934 6.41983 11.1107C6.57161 11.8642 7.381 12.474 7.95767 12.3402Z",
    fill: "white"
  }), __jsx("path", {
    d: "M14.5289 16.5788L13.244 11.5172C12.6673 9.60359 11.1901 8.85999 8.89352 8.85999H5.98478C3.68813 8.85999 2.21099 9.60359 1.6343 11.5172L0.354454 16.5788C0.131872 17.6248 0.561861 18.3982 1.61407 18.5618L3.4099 18.8642V20.5299H11.5038V18.884L13.2895 18.5816C14.3215 18.3982 14.7515 17.6248 14.5289 16.5788ZM8.73672 18.3437C8.31806 18.4807 7.86524 18.2593 7.72529 17.849C7.72524 17.8486 7.72506 17.8483 7.72494 17.8479L7.54285 17.3274V17.2878C7.41153 16.6895 6.9197 16.2311 6.30348 16.1326L2.58028 15.4336C2.67295 14.639 3.05134 13.9026 3.64766 13.3564C4.21251 12.7962 4.88012 12.3455 5.61549 12.0278C5.66608 13.1432 6.35911 13.9067 7.60355 14.1794C7.78098 14.2116 7.95993 14.2348 8.13976 14.2488L9.23247 17.3522C9.37579 17.7584 9.15591 18.2016 8.74133 18.3421C8.73981 18.3426 8.7383 18.3432 8.73672 18.3437ZM9.59667 13.7878C8.97078 13.7884 8.34553 13.7486 7.72494 13.6687C6.20733 13.3316 6.15171 12.2608 6.17194 11.8047C6.03081 11.5931 5.93606 11.3551 5.89372 11.1057C5.79761 10.4414 6.73853 9.90599 7.57322 9.90599C7.74681 9.90079 7.91952 9.93296 8.07906 10.0002C9.00547 9.99061 9.79644 10.6535 9.93055 11.5519C10.0216 12.2807 10.8613 12.2212 10.8411 12.7318C10.8259 13.334 10.3154 13.81 9.70098 13.7951C9.66611 13.7942 9.6313 13.7918 9.59667 13.7878Z",
    fill: "white"
  }));
}
export default GeneralPracticeHappy;
import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from 'react';
function ViewOnMap(props) {
  return __jsx("svg", _extends({}, props, {
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }), __jsx("path", {
    d: "M5.33317 12L0.666504 14.6666V3.99998L5.33317 1.33331M5.33317 12L10.6665 14.6666M5.33317 12V1.33331M10.6665 14.6666L15.3332 12V1.33331L10.6665 3.99998M10.6665 14.6666V3.99998M10.6665 3.99998L5.33317 1.33331"
  }));
}
export default ViewOnMap;
import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
var __jsx = React.createElement;
function MarkerEquine(props) {
  return __jsx("svg", _extends({
    xmlns: "http://www.w3.org/2000/svg",
    width: "29",
    height: "43",
    viewBox: "0 0 29 43",
    fill: "none"
  }, props), __jsx("path", {
    fill: "#2A9E41",
    stroke: "#fff",
    strokeWidth: "2",
    d: "M14.5 1c4.813 0 8.235 1.99 10.425 4.915C27.087 8.802 28 12.53 28 16c0 1.468-.522 3.383-1.28 5.427-.768 2.074-1.823 4.392-2.974 6.709-2.304 4.635-5.032 9.33-6.735 12.172a2.915 2.915 0 0 1-5.022 0c-1.703-2.842-4.43-7.537-6.735-12.172-1.151-2.317-2.206-4.635-2.975-6.71C1.522 19.384 1 17.469 1 16c0-3.47.913-7.198 3.074-10.085C6.264 2.99 9.687 1 14.5 1Z"
  }), __jsx("g", {
    fill: "#fff",
    clipPath: "url(#a)"
  }, __jsx("path", {
    d: "M17.349 9.462A9.208 9.208 0 0 0 16.7 7.24a2.71 2.71 0 0 0-.514.672c.103.336.225.727.332 1.11.265.13.545.277.83.439Z"
  }), __jsx("path", {
    d: "M17.131 15.294c1.343.395 2.351.478 3.161 1a.346.346 0 0 0 .182.051c.585 0 1.407-1.414 1.186-2.284-.202-.75-3.05-3.556-5.631-4.631-.261-.976-.668-2.189-.668-2.189s-2.316 1.58-3.363 3.631c-.395.747-.502 2.427-.067 3.79a7.569 7.569 0 0 1-1.185-2.395c-.231 1.447-.24 2.921-.028 4.37 0 0-1.186-1.54-1.344-3.137-.877.933-1.88 2.04-1.88 2.04s-.087 1.516 1.213 3.279a7.809 7.809 0 0 0 3.757 2.647 15.78 15.78 0 0 0 3.162-5.978c-.198-.344-.652-1.134-.625-1.798.297.818 1.031 1.268 2.13 1.604Zm-.968-4.69a.423.423 0 1 1-.419.426v-.007a.423.423 0 0 1 .419-.42Z"
  })), __jsx("defs", null, __jsx("clipPath", {
    id: "a"
  }, __jsx("path", {
    fill: "#fff",
    d: "M7 7h15v15H7z"
  }))));
}
export default MarkerEquine;